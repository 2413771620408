// import TaskList from '@/lib/models/Client/TaskList';
import { TaskListError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get (orgId, name) {
		const params = new URLSearchParams({
			include: 'checklist,scheduleTasks.schedule.defaultForLocations,scheduleTasksUntimed.schedule.defaultForLocations',
			'filter[is_checklist]': 1,
			'filter[organization_id]': orgId,
		});
		if (name) {
			params.set('filter[name]', name);
		}

		const response = await $http.get(`/api/tasks?${params}`);
		if (response.data) {
			return response.data.data;
		} else {
			throw new TaskListError('Error retrieving task list');
		}
	},

	async getAssignedToBranches (id) {
		const response = await $http.get(`/api/tasks/${id}?include=scheduleTasks.schedule.defaultForLocations,scheduleTasksUntimed.schedule.defaultForLocations&type=simple`);

		if (response.status === 200) {
			return response.data;
		} else {
			throw new TaskListError('Error retrieving Branches for Task List');
		}
	},

	async assign (object) {
		const response = await $http.post('/api/schedules/assignTaskToLocation', object);
		if (response.status === 204) {
			return response;
		} else {
			throw new TaskListError('Error unassigning task list');
		}
	},

	async deassign (object) {
		object.param_case = 'DE-ASSIGN-CL-TEMPLATE-CLIENT';

		const response = await $http.post('/api/client/managecltemplates', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskListError('Error unassigning task list');
		}
	},

	async cloneClTemplate (body) {
		const response = await $http.post(`/api/organizations/${body.organization_id}/tasks`, body);
		if (response.status === 201) {
			return response;
		} else {
			throw new TaskListError('Error cloning task list');
		}
	},
	async taskDetails (id) {
		const response = await $http.get(`/api/tasks/${id}/checklist`);
		if (response.status === 200) {
			return response;
		} else {
			throw new TaskListError('Error cloning task list');
		}
	},
	async updateClTemplate (object) {
		object.param_case = 'UPDATE-TASKING-TEMPLATE';

		const response = await $http.post('/api/client/managecltemplatesdasdas312312', object);

		if (response.status === 200) {
			return response.data.body;
		} else {
			throw new TaskListError('Error cloning task list');
		}
	},

	async deleteClTemplate (object) {
		object.param_case = 'UPDATE-TASKING-TEMPLATE';

		const response = await $http.delete(`/api/tasks/deleteChecklistItemByTaskId/${object.task_id}/${object.id}`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new TaskListError('Error cloning task list');
		}
	},

	async updateClTemplateOrder (object) {
		object.param_case = 'UPDATE-TASKING-TEMPLATE-ORDER';
		const response = await $http.patch(`/api/tasks/reorderChecklistByTaskId/${object.task_id}`, object);
		if (response.status === 204) {
			return response;
		} else {
			throw new TaskListError('Error cloning task list');
		}
	},
	// koljo
	async updateClTemplateDetails (object) {
		object.param_case = 'UPDATE-TASKING-TEMPLATE-ORDER';
		const response = await $http.patch(`/api/tasks/editChecklistItem/${object.task_id}/${object.id}`, object);

		if (response.status === 200) {
			return response;
		} else {
			throw new TaskListError('Error cloning task list');
		}
	},

	async updateClTemplateName (object) {
		// object.param_case = 'UPDATE-TASKING-TEMPLATE-NAME';

		const response = await $http.patch(`/api/tasks/${object.id}?include=scheduleTasks.task`, object);
		if (response.status === 200) {
			return response;
		} else {
			throw new TaskListError('Error updating Task List Name');
		}
	},

	async import () {
		const body = {
			param_case: 'IMPORT-TEMPLATE-FROM-ADMIN',
		};

		const response = await $http.post('/api/client/2', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskListError('Error importing task lists');
		}
	},
	async deassignTask (object) {
		const response = await $http.delete(`/api/schedules/deassignTaskFromLocation/${object.taskid}/${object.locationid}`);
		if (response.status === 204) {
			return response;
		} else {
			throw new TaskListError('Error removing task lists');
		}
	},
	async delete (id) {
		const response = await $http.delete(`/api/tasks/${id}`);
		if (response.status === 204) {
			return response;
		} else {
			throw new TaskListError('Error deleting task lists');
		}
	},
};
